import axios from "axios";
import {
  //nameTitles,
  // VehicleLst,
  //FuelLst,
  //langLst,
  serverURLs,
  //complainLst,
  //complaintStatusLst,
} from "@/store/sitedata";
import {
  GET_TITLES,
  GET_LANGUAGES,
  GET_VEHICLE_TYPES,
  GET_VEHICLE_OWNER_TYPES,
  GET_FUEL_TYPES,
  GET_ADMIN_DOCMANAGER_URL,
  GET_USER_DOCMANAGER_URL,
  GET_COMPLAINT_PARTIES,
  GET_COMPLAINT_STATUS_LIST,
  GET_DEPART_REASONS,
  SET_VEHICLE_TYPES,
  SET_DATADICTIONARY_DATA,
  SET_VEHICLE_OWNER_TYPES,
  M_COMMON,
  SET_BACKGROUND_SYNC_ACTIVE,
  SET_BACKGROUND_SYNC_INACTIVE,
  FULL_RESET,
} from "@/store/mutation-list"; //SET_TITLES
export const dd = {
  namespaced: true,
  state: {
    titles: [], //nameTitles.nTitle, //done
    vehicleTypes: [],
    vehicleOwnerTypes: [],
    fuelTypes: [], //FuelLst.fl, //done
    languages: [], //langLst.tLangs, //done
    serverLinks: serverURLs,
    complaintParties: [], //complainLst.complaintParties, //done
    complaintStatusTypes: [], //complaintStatusLst.complaintStatusTypes, //done
    departReasons: [],
  },
  actions: {
    [SET_VEHICLE_TYPES]({ commit, dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });

      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getvehicletypes", "getvehicletypes");

      axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          commit("SET_VEHICLE_TYPES", rep);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
    [SET_VEHICLE_OWNER_TYPES]({ commit, dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });

      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getvehicleownertypes", "getvehicleownertypes");

      axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          commit("SET_VEHICLE_OWNER_TYPES", rep);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },

    //lets get all dd in one go
    [SET_DATADICTIONARY_DATA]({ commit, dispatch, rootGetters }) {
      dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_ACTIVE}`, null, {
        root: true,
      });

      //SECURE Token
      const token = rootGetters["M_AUTH/TOKEN"];

      let fd = new FormData();
      fd.append("token", token); //adding token
      fd.append("getalldatadictionarylists", "getalldatadictionarylists");

      axios
        .post("ctrouV2.php", fd)
        .then(function (response) {
          let rep = response.data;
          //console.log(rep);
          commit("SET_DATADICTIONARY_DATA", rep);
        })
        .finally(() => {
          dispatch(`${M_COMMON}${SET_BACKGROUND_SYNC_INACTIVE}`, null, {
            root: true,
          });
        });
    },
  },
  mutations: {
    [SET_VEHICLE_TYPES](state, payload) {
      state.vehicleTypes = payload;
    },
    [SET_VEHICLE_OWNER_TYPES](state, payload) {
      state.vehicleOwnerTypes = payload;
    },
    [SET_DATADICTIONARY_DATA](state, payload) {
      state.vehicleTypes = payload.vehi_types;
      state.vehicleOwnerTypes = payload.vehi_owner_types;
      state.titles = payload.name_titles;
      state.fuelTypes = payload.fuel_types;
      state.languages = payload.all_langs;
      state.complaintParties = payload.comp_parties;
      state.complaintStatusTypes = payload.comp_status_types;
      state.departReasons = payload.depart_reasons;
    },
    [FULL_RESET](state) {
      state.vehicleTypes = [];
      state.vehicleOwnerTypes = [];
      state.titles = [];
      state.fuelTypes = [];
      state.languages = [];
      state.complaintParties = [];
      state.complaintStatusTypes = [];
      state.departReasons = [];
    },
  },
  getters: {
    [GET_TITLES]: (state) => state.titles,
    [GET_LANGUAGES]: (state) => state.languages,
    [GET_VEHICLE_TYPES]: (state) => {
      return state.vehicleTypes;
    },
    [GET_VEHICLE_OWNER_TYPES]: (state) => {
      return state.vehicleOwnerTypes;
    },
    [GET_FUEL_TYPES]: (state) => state.fuelTypes,
    [GET_ADMIN_DOCMANAGER_URL]: (state) =>
      state.serverLinks.adminDocManagerPath,
    [GET_USER_DOCMANAGER_URL]: (state) => state.serverLinks.userDocManagerPath,
    [GET_COMPLAINT_PARTIES]: (state) => state.complaintParties,
    [GET_COMPLAINT_STATUS_LIST]: (state) => state.complaintStatusTypes,
    [GET_DEPART_REASONS]: (state) => state.departReasons,
  },
};
